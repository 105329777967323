/* eslint-disable no-extend-native */
String.prototype.toPascalCase = function () {
	return this
		.replace(/\W+/g, ' ')
		.replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
		.replace(/\s/g, '');
};

String.prototype.toCamelCase = function () {
	return this
		.toPascalCase()
		.replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
};
