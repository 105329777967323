import { useEffect, useState } from 'react';
import { getData } from './baseFetch';

export function useFetch(path) {
	const [result, setResult] = useState({ loading: true, data: null });

	useEffect(() => {
		async function fetchData() {
			const data = await getData(path);
			setResult({ loading: false, data });
		}

		fetchData();
	}, [path]);

	return result;
}