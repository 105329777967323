export default function loadActiveInfrastructureLayers(selectedStyle, currentLayers) {
	if (currentLayers.length === 1) {
		return selectedStyle;
	} else {
		var updatedStyles = [];
		for (var layer of currentLayers) {
			if (layer === '') {
				continue;
			} else {
				switch (layer) {
					case 'road':
						var deletionRoadIndex = 0;
						var roadCount = 0;
						if (selectedStyle.length === 0) {
							updatedStyles.push({ 'featureType': 'road', 'elementType': 'geometry', 'stylers': [{ 'color': '#778899' }, { 'visibility': 'on' }] });
						}

						for (let style of selectedStyle) {
							if ('featureType' in style) {
								if (style['featureType'].substring(0, 4) === 'road') {
									updatedStyles.push({ 'featureType': 'road', 'elementType': 'geometry', 'stylers': [{ 'color': '#778899' }, { 'visibility': 'on' }] });
									roadCount += 1;
									deletionRoadIndex += 1;
									continue;
								} else {
									updatedStyles.push(style);
									deletionRoadIndex += 1;
								}
							} else {
								updatedStyles.push(style);
								deletionRoadIndex += 1;
							}
						}

						if (roadCount === 0) {
							updatedStyles.push({ 'featureType': 'road', 'elementType': 'geometry', 'stylers': [{ 'color': '#778899' }, { 'visibility': 'on' }] });
						}

						for (var i = 0; i < deletionRoadIndex; i++) {
							selectedStyle.shift();
						}

						break;
					case 'railway':
						var deletionRailwayIndex = 0;
						var railwayCount = 0;
						if (selectedStyle.length === 0) {
							updatedStyles.push({ 'featureType': 'transit.line', 'elementType': 'geometry', 'stylers': [{ 'color': '#ff00ff' }, { 'visibility': 'on' }, { 'weight': 3 }] });
						}

						for (let style of selectedStyle) {
							if ('featureType' in style) {
								if (style['featureType'].substring(0, 7) === 'transit') {
									updatedStyles.push({ 'featureType': 'transit.line', 'elementType': 'geometry', 'stylers': [{ 'color': '#ff00ff' }, { 'visibility': 'on' }, { 'weight': 3 }] });
									railwayCount += 1;
									deletionRailwayIndex += 1;
									continue;
								} else {
									updatedStyles.push(style);
									deletionRailwayIndex += 1;
								}
							} else {
								updatedStyles.push(style);
								deletionRailwayIndex += 1;
							}
						}

						if (railwayCount === 0) {
							updatedStyles.push({ 'featureType': 'transit.line', 'elementType': 'geometry', 'stylers': [{ 'color': '#ff00ff' }, { 'visibility': 'on' }, { 'weight': 3 }] });
						}

						for (var j = 0; j < deletionRailwayIndex; j++) {
							selectedStyle.shift();
						}

						break;
					case 'government':
						var deletionGovtIndex = 0;
						var govtCount = 0;
						if (selectedStyle.length === 0) {
							updatedStyles.push({ 'featureType': 'poi.government', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#002eff' }, { 'visibility': 'on' }] });
							updatedStyles.push({ 'featureType': 'poi.government', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#002eff' }, { 'visibility': 'on' }] });

						}

						for (let style of selectedStyle) {
							if ('featureType' in style) {
								if (style['featureType'].substring(0, 14) === 'poi.government') {
									updatedStyles.push({ 'featureType': 'poi.government', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#002eff' }, { 'visibility': 'on' }] });
									updatedStyles.push({ 'featureType': 'poi.government', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#002eff' }, { 'visibility': 'on' }] });
									govtCount += 1;
									deletionGovtIndex += 1;
									continue;
								} else {
									updatedStyles.push(style);
									deletionGovtIndex += 1;
								}
							} else {
								updatedStyles.push(style);
								deletionGovtIndex += 1;
							}
						}

						if (govtCount === 0) {
							updatedStyles.push({ 'featureType': 'poi.government', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#002eff' }, { 'visibility': 'on' }] });
							updatedStyles.push({ 'featureType': 'poi.government', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#002eff' }, { 'visibility': 'on' }] });
						}

						for (var k = 0; k < deletionGovtIndex; k++) {
							selectedStyle.shift();
						}

						break;
					case 'hospital':
						var deletionMedicalIndex = 0;
						var medicalCount = 0;
						if (selectedStyle.length === 0) {
							updatedStyles.push({ 'featureType': 'poi.medical', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#990000' }, { 'visibility': 'on' }] });
							updatedStyles.push({ 'featureType': 'poi.medical', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#990000' }, { 'visibility': 'on' }] });
						} else {
							for (let style of selectedStyle) {
								if ('featureType' in style) {
									if (style['featureType'].substring(0, 14) === 'poi.medical') {
										updatedStyles.push({ 'featureType': 'poi.medical', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#990000' }, { 'visibility': 'on' }] });
										updatedStyles.push({ 'featureType': 'poi.medical', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#990000' }, { 'visibility': 'on' }] });
										medicalCount += 1;
										deletionMedicalIndex += 1;
										continue;
									} else {
										updatedStyles.push(style);
										deletionMedicalIndex += 1;
									}
								} else {
									updatedStyles.push(style);
									deletionMedicalIndex += 1;
								}
							}

							if (medicalCount === 0) {
								updatedStyles.push({ 'featureType': 'poi.medical', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#990000' }, { 'visibility': 'on' }] });
								updatedStyles.push({ 'featureType': 'poi.medical', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#990000' }, { 'visibility': 'on' }] });
							}

							for (var l = 0; l < deletionMedicalIndex; l++) {
								selectedStyle.shift();
							}
						}

						break;
					case 'school':
						var deletionSchoolIndex = 0;
						var schoolCount = 0;
						if (selectedStyle.length === 0) {
							updatedStyles.push({ 'featureType': 'poi.school', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#20b2aa' }, { 'visibility': 'on' }] });
							updatedStyles.push({ 'featureType': 'poi.school', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#20b2aa' }, { 'visibility': 'on' }] });
						} else {
							for (let style of selectedStyle) {
								if ('featureType' in style) {
									if (style['featureType'].substring(0, 14) === 'poi.medical') {
										updatedStyles.push({ 'featureType': 'poi.school', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#20b2aa' }, { 'visibility': 'on' }] });
										updatedStyles.push({ 'featureType': 'poi.school', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#20b2aa' }, { 'visibility': 'on' }] });
										schoolCount += 1;
										deletionSchoolIndex += 1;
										continue;
									} else {
										updatedStyles.push(style);
										deletionSchoolIndex += 1;
									}
								} else {
									updatedStyles.push(style);
									deletionSchoolIndex += 1;
								}
							}

							if (schoolCount === 0) {
								updatedStyles.push({ 'featureType': 'poi.school', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#20b2aa' }, { 'visibility': 'on' }] });
								updatedStyles.push({ 'featureType': 'poi.school', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#20b2aa' }, { 'visibility': 'on' }] });
							}

							for (var m = 0; m < deletionSchoolIndex; m++) {
								selectedStyle.shift();
							}
						}

						break;
					default:
						var deletionWorshipIndex = 0;
						var worshipCount = 0;
						if (selectedStyle.length === 0) {
							updatedStyles.push({ 'featureType': 'poi.place_of_worship', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#a1a907' }, { 'visibility': 'on' }] });
							updatedStyles.push({ 'featureType': 'poi.place_of_worship', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#a1a907' }, { 'visibility': 'on' }] });
						} else {
							for (let style of selectedStyle) {
								if ('featureType' in style) {
									if (style['featureType'].substring(0, 14) === 'poi.medical') {
										updatedStyles.push({ 'featureType': 'poi.place_of_worship', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#a1a907' }, { 'visibility': 'on' }] });
										updatedStyles.push({ 'featureType': 'poi.place_of_worship', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#a1a907' }, { 'visibility': 'on' }] });
										worshipCount += 1;
										deletionWorshipIndex += 1;
										continue;
									} else {
										updatedStyles.push(style);
										deletionWorshipIndex += 1;
									}
								} else {
									updatedStyles.push(style);
									deletionWorshipIndex += 1;
								}
							}

							if (worshipCount === 0) {
								updatedStyles.push({ 'featureType': 'poi.place_of_worship', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#a1a907' }, { 'visibility': 'on' }] });
								updatedStyles.push({ 'featureType': 'poi.place_of_worship', 'elementType': 'labels.icon', 'stylers': [{ 'color': '#a1a907' }, { 'visibility': 'on' }] });
							}

							for (var n = 0; n < deletionWorshipIndex; n++) {
								selectedStyle.shift();
							}
						}

						break;
				}

			}
		}

		return updatedStyles;
	}
}
