import React from 'react';
import { EventsProvider } from './EventsContext';
import { FilterProvider } from './FilterContext';
import { InfrastructureProvider } from './InfrastructureContext';
import { MapStyleProvider } from './MapStyleContext';
import { MenuProvider } from './MenuContext';
import { ToolsProvider } from './ToolsContext';

const composeProviders = (providers) => {
	return providers.reduce((Acc, Provider) => {
		return (
			props =>
				<Provider>
					<Acc {...props} />
				</Provider>
		);
	});
};

const VisualizerProvider = composeProviders([
	MenuProvider,
	MapStyleProvider,
	ToolsProvider,
	InfrastructureProvider,
	FilterProvider,
	EventsProvider
]);

export default VisualizerProvider;