import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { ApplicationPaths, LoginActions } from './ApiAuthorizationConstants';
import Login from './Login';

const loginAction = (name) => {
	return (<Login action={name}></Login>);
};

const ApiAuthorizationRoutes = () => {
	return (
		<Fragment>
			<Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
			<Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
			<Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
			<Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
			<Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
		</Fragment>);
};

export default ApiAuthorizationRoutes;
