import React from 'react';
import { Container } from 'reactstrap';

export default function Layout(props) {
	return (
		<Container className="p-0" fluid>
			{props.children}
		</Container>
	);
}
