export default function getStyles() {

	const mapStyleResouces = [
		{
			name: 'Default',
			value: 'default',
			style: []
		},
		{
			name: 'Grayscale (Clean)',
			value: 'cleanGray',
			style: [
				{
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#f5f5f5'
						}
					]
				},
				{
					'elementType': 'labels.icon',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#616161'
						}
					]
				},
				{
					'elementType': 'labels.text.stroke',
					'stylers': [
						{
							'color': '#f5f5f5'
						}
					]
				},
				{
					'featureType': 'administrative.country',
					'elementType': 'geometry.stroke',
					'stylers': [
						{
							'color': '#788085'
						}
					]
				},
				{
					'featureType': 'administrative.land_parcel',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'administrative.land_parcel',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#bdbdbd'
						},
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'administrative.locality',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'administrative.locality',
					'elementType': 'labels',
					'stylers': [
						{
							'lightness': 5
						}
					]
				},
				{
					'featureType': 'administrative.neighborhood',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'administrative.neighborhood',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'poi',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'road',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'transit',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'water',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#9f9f9f'
						}
					]
				}]
		}, {
			name: 'Subtle Grayscale',
			value: 'subtleGray',
			style: [{
				'featureType': 'landscape',
				'stylers': [{
					'saturation': -100
				}, {
					'lightness': 65
				}, {
					'visibility': 'on'
				}]
			},
			{
				'elementType': 'labels.icon',
				'stylers': [
					{
						'visibility': 'off'
					}
				]
			},
			{
				'featureType': 'road.highway',
				'stylers': [{
					'saturation': -100
				}, {
					'visibility': 'simplified'
				}]
			}, {
				'featureType': 'road.arterial',
				'stylers': [{
					'saturation': -100
				}, {
					'lightness': 30
				}, {
					'visibility': 'on'
				}]
			}, {
				'featureType': 'road.local',
				'stylers': [{
					'saturation': -100
				}, {
					'lightness': 40
				}, {
					'visibility': 'on'
				}]
			}, {
				'featureType': 'transit',
				'stylers': [{
					'saturation': -100
				}, {
					'visibility': 'simplified'
				}]
			}, {
				'featureType': 'administrative.province',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'water',
				'elementType': 'labels',
				'stylers': [{
					'visibility': 'on'
				}, {
					'lightness': -25
				}, {
					'saturation': -100
				}]
			}, {
				'featureType': 'water',
				'elementType': 'geometry',
				'stylers': [{
					'hue': '#ffff00'
				}, {
					'lightness': -25
				}, {
					'saturation': -97
				}]
			}]
		}, {
			name: 'Ultra light',
			value: 'ultralight',
			style: [{
				'featureType': 'water',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#e9e9e9'
				}, {
					'lightness': 17
				}]
			}, {
				'featureType': 'landscape',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#f5f5f5'
				}, {
					'lightness': 20
				}]
			}, {
				'featureType': 'road.highway',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 17
				}]
			}, {
				'featureType': 'road.highway',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 29
				}, {
					'weight': 0.2
				}]
			}, {
				'featureType': 'road.arterial',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 18
				}]
			}, {
				'featureType': 'road.local',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 16
				}]
			}, {
				'featureType': 'poi',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#f5f5f5'
				}, {
					'lightness': 21
				}]
			}, {
				'featureType': 'poi.park',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#dedede'
				}, {
					'lightness': 21
				}]
			}, {
				'elementType': 'labels.text.stroke',
				'stylers': [{
					'visibility': 'on'
				}, {
					'color': '#ffffff'
				}, {
					'lightness': 16
				}]
			}, {
				'elementType': 'labels.text.fill',
				'stylers': [{
					'saturation': 36
				}, {
					'color': '#333333'
				}, {
					'lightness': 40
				}]
			}, {
				'elementType': 'labels.icon',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'transit',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#f2f2f2'
				}, {
					'lightness': 19
				}]
			}, {
				'featureType': 'administrative',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#fefefe'
				}, {
					'lightness': 20
				}]
			}, {
				'featureType': 'administrative',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#fefefe'
				}, {
					'lightness': 17
				}, {
					'weight': 1.2
				}]
			}]
		}, {
			name: 'Clean (no labels)',
			value: 'cleanNoLabel',
			style: [{
				'featureType': 'administrative.country',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'visibility': 'on'
				}, {
					'weight': 2.1
				}]
			}, {
				'featureType': 'poi',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'road.local',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'road.arterial',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'road.highway',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'administrative.province',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'weight': 1.2
				}]
			}, {
				'featureType': 'landscape',
				'stylers': [{
					'visibility': 'on'
				}, {
					'lightness': 100
				}, {
					'saturation': '- 100'
				}]
			}, {
				'featureType': 'administrative.province',
				'elementType': 'labels',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'administrative.locality',
				'stylers': [{
					'visibility': 'off'
				}]
			}]
		}, {
			name: 'Clean (roads)',
			value: 'cleanRoads',
			style: [{
				'featureType': 'administrative.country',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'visibility': 'on'
				}, {
					'weight': 2.1
				}]
			}, {
				'featureType': 'poi',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'road.local',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'road.arterial',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'road.highway',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'administrative.province',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'weight': 1.2
				}]
			}, {
				'featureType': 'landscape',
				'stylers': [{
					'visibility': 'on'
				}, {
					'lightness': 100
				}, {
					'saturation': '- 100'
				}]
			}, {
				'featureType': 'administrative.province',
				'elementType': 'labels',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'administrative.locality',
				'stylers': [{
					'visibility': 'on'
				}]
			}]
		}];

	return mapStyleResouces;
}
