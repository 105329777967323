/*global google*/
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { useGoogleMap } from '@react-google-maps/api';
import { useContext, useEffect, useRef } from 'react';
import { EventsContext } from '../../contexts/visualizer/EventsContext';
import { useToolsContext } from '../../contexts/visualizer/ToolsContext';
import './Clusterer.css';

const numberColors = 10;  // From the .custom-clustericon-n styles in Clusterer.css

const toolName = 'layers';

const styles = [
	{
		height: 30,
		width: 30
	},
	{
		height: 40,
		width: 40
	},
	{
		height: 50,
		width: 50
	},
	{
		height: 60,
		width: 60
	},
	{
		height: 70,
		width: 70
	}
];

const imageUrl =
	'https://chart.apis.google.com/chart?cht=mm&chs=24x32&' +
	'chco=FFFFFF,008CFF,000000&ext=.png';

export default function Clusterer() {

	const { events } = useContext(EventsContext);
	const [toolStates] = useToolsContext();

	const map = useGoogleMap();

	const markerClusterers = useRef([]);

	useEffect(() => {
		const clearAllMarkers = () => {
			if (markerClusterers.current) {
				markerClusterers.current.forEach((clusterer) => {
					clusterer.clearMarkers();
				});

				markerClusterers.current = [];
			}
		};

		const createLayers = (events) => {
			let eventLayers = new Map();
			const selectedOption = toolStates[toolName];

			if (!selectedOption || 'none' in selectedOption) {
				eventLayers.set('all', events);
			}
			else {
				const useCategory = 'unrestCategory' in selectedOption;

				events.forEach(e => {
					const category = useCategory ? e.eventCategory : e.countryCode;
					if (eventLayers.has(category)) {
						eventLayers.get(category).push(e);
					}
					else {
						eventLayers.set(category, [e]);
					}
				});
			}

			return [...eventLayers.values()];
		};

		const clusterMarkers = (events, clustererOptions) => {
			const markerImage = new google.maps.MarkerImage(
				imageUrl,
				new google.maps.Size(24, 32)
			);

			const markers = events.map((event) => {
				return new google.maps.Marker({
					position: { lat: event.latitude, lng: event.longitude },
					icon: markerImage
				});
			});

			markerClusterers.current.push(new MarkerClusterer(map, markers, clustererOptions));
		};

		clearAllMarkers();

		const layers = createLayers(events);

		layers.forEach((layerEvents, index) => {
			const clustererOptions = {
				clusterClass: `custom-clustericon custom-clustericon-${index % numberColors}`,
				minimumClusterSize: 3,
				styles: styles
			};

			clusterMarkers(layerEvents, clustererOptions);
		});

	}, [events, map, toolStates]);

	return null;
}

