import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FilterContext } from '../../../../../contexts/visualizer/FilterContext';

const { SHOW_PARENT } = TreeSelect;

const CountryFilter = forwardRef((_, ref) => {

	const { prepareQuery } = useContext(FilterContext);

	const [isError, setIsError] = useState(true);
	const [value, setValue] = useState([]);
	const [treeData, setTreeData] = useState([]);

	const populateTreeData = async () => {
		const response = await fetch('api/v1/country');
		const data = await response.json();
		setTreeData(data);
	};

	useEffect(() => {
		populateTreeData();
	}, []);

	useImperativeHandle(ref, () => ({
		onRevert(selectedNodes) {
			const length = selectedNodes?.length;
			setIsError(!length || length === 0);
			setValue(selectedNodes);
		},

		onClear() {
			setIsError(true);
			setValue([]);
		}
	}));

	const onChange = async (selectedNodes) => {
		setIsError(selectedNodes.length === 0);
		setValue(selectedNodes);
		await prepareQuery({ country: selectedNodes });
	};

	const treeProps = {
		treeData,
		value: value,
		onChange: onChange,
		treeCheckable: true,
		treeNodeFilterProp: 'title',
		showCheckedStrategy: SHOW_PARENT,
		placeholder: 'Select countries',
		style: {
			width: '100%'
		}
	};

	return (
		<div>
			<div className="d-flex">
				<h4>Country</h4>
				{isError && <Label className="text-danger" data-testid="filter-country-notification">*Required</Label>}
			</div>
			<FormGroup tag="fieldset">
				<TreeSelect {...treeProps} />
			</FormGroup>
		</div>
	);
});

export default CountryFilter;
