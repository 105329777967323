import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { EventsContext } from '../../../../contexts/visualizer/EventsContext';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';
import { useToolsContext } from '../../../../contexts/visualizer/ToolsContext';
import '../Tool.css';

const toolName = 'layers';

const layers = [
	{ 'label': 'None', 'value': 'none' },
	{ 'label': 'Unrest category', 'value': 'unrestCategory' },
	{ 'label': 'Country', 'value': 'country' }
];

export default function Layers() {
	const { setSelectedItem } = useContext(MenuContext);
	const { isLoaded } = useContext(EventsContext);
	const [toolStates, setToolStates] = useToolsContext();

	const getInitialState = (item) => {
		const savedState = toolStates[toolName];
		if (savedState !== undefined) {
			return savedState;
		}

		const checkableItems = {};

		checkableItems[item.value] = true;

		setToolStates(draft => {
			draft[toolName] = checkableItems;
		});

		return checkableItems;
	};

	const [checkableItems, setCheckableItems] = useState();

	// useEffect here should run only the first time the component is rendered,
	// this is achieved with the empty array as the dependencies.
	// ES Lint gives a warning about missing dependencies, but the behavior is intended.
	// Disabling the warning below.
	useEffect(() => {
		const defaultValue = layers[0];
		const initialState = getInitialState(defaultValue);
		setCheckableItems(initialState);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setToolStates(draft => {
			draft[toolName] = checkableItems;
		});
	}, [checkableItems, setToolStates]);

	const checkItem = (item, e) => {
		setCheckableItems({
			[item]: e.target.checked
		});
	};

	const renderContent = () => {
		if (isLoaded) {
			return (
				<FormGroup tag="fieldset">
					{layers.map(layerBy => (
						<FormGroup key={layerBy.value} check>
							<Label check>
								<Input
									type='radio'
									name={toolName}
									checked={(checkableItems && checkableItems[`${layerBy.value}`]) || false}
									value={layerBy.value}
									onChange={(e) => checkItem(layerBy.value, e)}
									data-testid={`${toolName}-${layerBy.value}`}
								/>
								{layerBy.label}
							</Label>
						</FormGroup>
					))}
				</FormGroup>
			);
		}
		else {
			return <p className="text-danger">No data has been loaded for the layers to be effective</p>;
		}
	};

	return (
		<Draggable>
			<div className="tool">
				<Card>
					<CardBody>
						<div className="clearfix">
							<h3 className="float-left mb-0 mr-2">Layers</h3>
							<Button onClick={() => setSelectedItem(null)} className="float-right" close />
						</div>
						<hr />
						{renderContent()}
					</CardBody>
				</Card>
			</div>
		</Draggable>
	);
}