import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';
import { useToolsContext } from '../../../../contexts/visualizer/ToolsContext';
import '../Tool.css';

const toolName = 'censusVariable';

export default function CensusVariable() {
	const { setSelectedItem } = useContext(MenuContext);
	const [toolStates, setToolStates] = useToolsContext();

	const getInitialState = (item) => {
		const savedState = toolStates[toolName];
		if (savedState !== undefined) {
			return savedState;
		}

		const checkableItems = {};

		checkableItems[item.value] = true;

		setToolStates(draft => {
			draft[toolName] = checkableItems;
		});

		return checkableItems;
	};

	const censusVariables = [
		{ 'label': 'None', 'value': 'none' },
		{ 'label': 'Literacy Rate', 'value': 'literacy' }
	];

	const [checkableItems, setCheckableItems] = useState();

	// useEffect here should run only the first time the component is rendered,
	// this is achieved with the empty array as the dependencies.
	// ES Lint gives a warning about missing dependencies, but the behavior is intended.
	// Disabling the warning below.
	useEffect(() => {
		const defaultValue = censusVariables[0];
		const initialState = getInitialState(defaultValue);
		setCheckableItems(initialState);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setToolStates(draft => {
			draft[toolName] = checkableItems;
		});
	}, [checkableItems, setToolStates]);

	const checkItem = (item, e) => {
		setCheckableItems({
			[item]: e.target.checked
		});
	};

	return (
		<Draggable>
			<div className="tool">
				<Card>
					<CardBody>
						<div className="clearfix">
							<h3 className="float-left mb-0 mr-2">Census-Variable</h3>
							<Button onClick={() => setSelectedItem(null)} className="float-right" close />
						</div>
						<hr />
						<FormGroup tag="fieldset">
							{censusVariables.map(census => (
								<FormGroup key={census.value} check>
									<Label check>
										<Input
											type='radio'
											name={toolName}
											checked={(checkableItems && checkableItems[`${census.value}`]) || false}
											value={census.value}
											onChange={(e) => checkItem(census.value, e)}
											data-testid={`${toolName}-${census.value}`}
										/>
										{census.label}
									</Label>
								</FormGroup>
							))}
						</FormGroup>
					</CardBody>
				</Card>
			</div>
		</Draggable>
	);
}