import React, { createContext, useState } from 'react';

const EventsContext = createContext();

function EventsProvider(props) {
	const [events, setEvents] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);

	return (
		<EventsContext.Provider value={{ events, setEvents, isLoaded, setIsLoaded }}>
			{props.children}
		</EventsContext.Provider>
	);
}

export { EventsContext, EventsProvider };
	