import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { InfrastructureContext } from '../../../../contexts/visualizer/InfrastructureContext';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';
import { useToolsContext } from '../../../../contexts/visualizer/ToolsContext';
import '../Tool.css';

const toolName = 'infrastructure';

export default function Infrastructure() {
	const { setSelectedItem } = useContext(MenuContext);
	const [toolStates, setToolStates] = useToolsContext();
	const { selectedLayers, setSelectedLayers } = useContext(InfrastructureContext);

	const getInitialState = (items) => {
		const savedState = toolStates[toolName];
		if (savedState !== undefined) {
			return savedState;
		}

		const checkableItems = {};

		items.forEach(item => {
			checkableItems[item.value] = false;
		});

		setToolStates(draft => {
			draft[toolName] = checkableItems;
		});

		return checkableItems;
	};

	const infrastructureTypes = [
		{ 'label': 'Roadways', 'value': 'road' },
		{ 'label': 'Railways', 'value': 'railway' },
		{ 'label': 'Government Buildings', 'value': 'government' },
		{ 'label': 'Places of Worship', 'value': 'worship' },
		{ 'label': 'Hospitals', 'value': 'hospital' },
		{ 'label': 'Educational Institutions', 'value': 'school' }
	];

	const [checkableItems, setCheckableItems] = useState();

	// useEffect here should run only the first time the component is rendered,
	// this is achieved with the empty array as the dependencies.
	// ES Lint gives a warning about missing dependencies, but the behavior is intended.
	// Disabling the warning below.
	useEffect(() => {
		const initialState = getInitialState(infrastructureTypes);
		setCheckableItems(initialState);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setToolStates(draft => {
			draft[toolName] = checkableItems;
		});
	}, [checkableItems, setToolStates]);

	const checkItem = (item, e) => {
		setCheckableItems({
			...checkableItems,
			[item]: e.target.checked
		});
		if (e.target.checked) {
			setSelectedLayers([...selectedLayers, item]);
		} else {
			setSelectedLayers(selectedLayers.filter(layer => item !== layer));
		}
	};

	return (
		<Draggable>
			<div className="tool">
				<Card>
					<CardBody>
						<div className="clearfix">
							<h3 className="float-left mb-0 mr-2">Infrastructure</h3>
							<Button onClick={() => setSelectedItem(null)} className="float-right" close />
						</div>
						<hr />
						<FormGroup tag="fieldset">
							{infrastructureTypes.map(infrastructure => (
								<FormGroup key={infrastructure.value} check>
									<Label check>
										<Input
											type='checkbox'
											name={toolName}
											checked={(checkableItems && checkableItems[`${infrastructure.value}`]) || false}
											value={infrastructure.value}
											onChange={(e) => checkItem(infrastructure.value, e)}
											data-testid={`${toolName}-${infrastructure.value}`}
										/>
										{infrastructure.label}
									</Label>
								</FormGroup>
							))}
						</FormGroup>
					</CardBody>
				</Card>
			</div>
		</Draggable>
	);
}
