import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import { MenuContext } from '../../contexts/visualizer/MenuContext';
import '../../utils/stringCaseUtils';
import Map from '../Map';
import Menu from '../menu/Menu';
import * as Tools from './tools';
import Filter from './tools/filter/Filter';

export default function VisualizerContent() {
	const { selectedItem } = useContext(MenuContext);

	const renderTool = () => {
		const componentName = selectedItem.toPascalCase();
		const Tool = Tools[componentName];

		return (
			<Tool />
		);
	};

	return (
		<Container className="p-0" fluid>
			<Menu />
			<Map />
			<Filter />
			<div className="row d-flex justify-content-center">
				{selectedItem && renderTool()}
			</div>
		</Container>
	);
}
