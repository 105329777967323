import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import LoadingBar from 'react-top-loading-bar';
import { Button, Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import { EventsContext } from '../../../../contexts/visualizer/EventsContext';
import { FilterContext } from '../../../../contexts/visualizer/FilterContext';
import AreaFilter from './categories/AreaFilter';
import CountryFilter from './categories/CountryFilter';
import DataSourceFilter from './categories/DataSourceFilter';
import DateFilter from './categories/DateFilter';
import UnrestFilter from './categories/UnrestFilter';
import './Filter.css';
import FilterNotificationMessage from './FilterNotificationMessage';

const batchSize = 5000;

export default function Filter() {

	const [collapsed, setCollapsed] = useState(true);

	const { eventCount, query, prepareQuery, allowFilter } = useContext(FilterContext);

	const { setEvents, setIsLoaded } = useContext(EventsContext);
	const [isFetchingEvents, setIsFetchingEvents] = useState(false);
	const [loadingProgress, setLoadingProgress] = useState(0);
	const [appliedFilter, setAppliedFilter] = useState();
	const [disableRevert, setDisableRevert] = useState(true);

	const isMounted = useRef(true);

	const unrestFilter = useRef();
	const countryFilter = useRef();
	const dataSourceFilter = useRef();
	const areaFilter = useRef();
	const dateFilter = useRef();

	const toggleFilter = () => {
		setCollapsed(!collapsed);
	};

	const revertOptions = async () => {
		dateFilter.current.onRevert(appliedFilter?.startDate, appliedFilter?.endDate);
		unrestFilter.current.onRevert(appliedFilter?.unrestEvent);
		countryFilter.current.onRevert(appliedFilter?.country);
		dataSourceFilter.current.onRevert(appliedFilter?.dataSource);
		areaFilter.current.onRevert();

		const tempFilter = { ...appliedFilter };
		tempFilter.areaFilter = null;
		await prepareQuery(tempFilter);
	};

	const clearOptions = async () => {
		unrestFilter.current.onClear();
		countryFilter.current.onClear();
		dataSourceFilter.current.onClear();
		areaFilter.current.onClear();
		dateFilter.current.onClear();
		await prepareQuery({
			startDate: null,
			endDate: null,
			unrestEvent: null,
			country: null,
			dataSource: null,
			areaFilter: null
		});
	};

	const populateEventData = useCallback(async () => {

		// Prevent sending request while another is in progress
		if (isFetchingEvents) {
			return;
		}

		setIsFetchingEvents(true);
		setDisableRevert(false);

		setLoadingProgress(1);

		const selectedFilter = { ...query };
		setAppliedFilter({ ...query });

		selectedFilter.batchSize = batchSize;

		const numberBatches = Math.ceil(eventCount / batchSize);
		const numberLoadingProgressSteps = numberBatches + 1;

		let allEvents = [];

		for (let i = 0; i < numberBatches; i++) {
			selectedFilter.startIndex = i * batchSize;
			const options = {
				method: 'POST',
				body: JSON.stringify(selectedFilter),
				headers: {
					'Content-Type': 'application/json'
				}
			};

			const response = await fetch('api/v1/event', options);
			const data = await response.json();
			allEvents.push(...data);
			const currentProgress = (i + 1) * 100 / numberLoadingProgressSteps;
			setLoadingProgress(currentProgress);
		}

		// Safely set state if the component is mounted
		if (isMounted.current) {
			setIsFetchingEvents(false);
			setEvents(allEvents);
			setIsLoaded(true);
			setLoadingProgress(100);
		}
	}, [eventCount, isFetchingEvents, query, setEvents, setIsLoaded]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<div className="filter">
			<LoadingBar
				progress={loadingProgress}
				onLoaderFinished={() => setLoadingProgress(0)}
			/>
			<div className="d-flex">
				<Card onClick={toggleFilter} className="filter-open-button interactable" data-testid="filter-open-button">
					<CardBody>
						<div className="row flex-nowrap justify-content-center align-items-center px-1">
							Filter <FaChevronRight />
						</div>
					</CardBody>
				</Card>
			</div>
			<div
				className={`d-flex justify-content-start align-items-end filter-menu ${collapsed ? '' : 'show'}`}
				data-testid="filter-menu">

				<Card className="filter-content interactable">
					<CardBody>
						<CardTitle>
							<div className="d-flex flex-wrap align-items-center justify-content-between">
								<h1>Filter</h1>
								<div className="filter-buttons-container">
									<Button
										outline
										disabled={disableRevert}
										className="mx-1"
										onClick={revertOptions}
										data-testid="filter-revert"
									>
										Revert
									</Button>
									<Button
										outline
										className="mx-1"
										onClick={clearOptions}
										data-testid="filter-clear"
									>
										Clear
									</Button>
									<Button
										color="primary"
										className="mx-1"
										disabled={isFetchingEvents || !allowFilter}
										onClick={populateEventData}
										data-testid="filter-apply"
									>
										Apply
									</Button>
								</div>
							</div>
							<FilterNotificationMessage />
						</CardTitle>
						{loadingProgress > 0 &&
							<Spinner />
						}
						<DateFilter ref={dateFilter} />
						<UnrestFilter ref={unrestFilter} />
						<CountryFilter ref={countryFilter} />
						<DataSourceFilter ref={dataSourceFilter} />
						<AreaFilter ref={areaFilter} />
					</CardBody>
				</Card>
				<div className="d-flex">
					<Card onClick={toggleFilter} className="filter-close-button interactable" data-testid="filter-close-button">
						<CardBody>
							<FaChevronRight className={`filter-close-icon ${collapsed ? '' : 'rotate180'}`} />
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}