import React, { useContext, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdAccountBalance, MdClearAll, MdInfoOutline, MdLightbulbOutline, MdMap, MdStoreMallDirectory } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Card, CardBody, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { MenuContext } from '../../contexts/visualizer/MenuContext';
import { useFetch } from '../../hooks/useFetch';
import LoginMenu from '../login/LoginMenu';
import './Menu.css';

export default function Menu() {
	const { selectedItem, setSelectedItem } = useContext(MenuContext);

	const { data } = useFetch('date');

	const [collapsed, setCollapsed] = useState(true);
	const [latestUpdate, setLatestUpdate] = useState([]);

	useEffect(() => {
		if (data != null) {
			setLatestUpdate(data.latestDate);
		}
	}, [latestUpdate, setLatestUpdate, data]);

	const toggleNavbar = () => {
		setCollapsed(!collapsed);
	};

	const lastUpdated = `Last Updated ${latestUpdate}`;

	const tools = [
		{
			'name': 'Infrastructure',
			'icon': <MdStoreMallDirectory />,
			'className': 'toggle-infrastructure-tool'
		},
		{
			'name': 'Census-Variable',
			'icon': <MdAccountBalance />,
			'className': 'toggle-censusVariable-tool'
		},
		{
			'name': 'Layers',
			'icon': <MdClearAll />,
			'className': 'toggle-layers-tool'
		},
		{
			'name': 'Map Style',
			'icon': <MdMap />,
			'className': 'toggle-mapStyles-tool'
		},
		{
			'name': 'Project Information',
			'icon': <MdInfoOutline />,
			'className': 'toggle-project-info'
		},
		{
			'name': 'Tutorial',
			'icon': <MdLightbulbOutline />
		}
	];

	const onClickNavItem = (item) => {
		setCollapsed(true);

		const isReselected = item === selectedItem;
		if (selectedItem) {
			setSelectedItem(null);
		}

		if (isReselected) {
			return;
		}

		setSelectedItem(item);
	};

	useEffect(() => {
		if (localStorage.getItem('tutorialComplete') === null) {
			setSelectedItem('Project Information');
		}
	}, [setSelectedItem]);

	return (
		<IconContext.Provider value={{ size: '1.5rem' }}>
			<div className="menu m-2">
				<Card>
					<CardBody className="px-2 py-1">
						<Navbar className="px-0" color="faded" light>
							<NavbarToggler onClick={toggleNavbar} className="mr-2" data-testid="navbar-toggler" />
							<NavbarBrand
								tag={Link}
								to="/"
								className="mr-auto">

								SURGE Visualizer <span className="updated-info-lg">{lastUpdated}</span>
							</NavbarBrand>
							<Collapse
								isOpen={!collapsed}
								navbar
								className="pt-2"
								data-testid="navbar-collapse">
								<span className="updated-info-sm">{lastUpdated}</span>
								<Nav navbar>
									{tools.map(tool => (
										<NavItem key={tool.name}>
											<NavLink
												onClick={() => onClickNavItem(tool.name)}
												active={tool.name === selectedItem}
												className={tool.className}
												data-testid={`navlink-${tool.name}`}>

												<div className="d-flex align-items-center px-1">
													{tool.icon}
													<div className="spacer" />
													{tool.name}
												</div>
											</NavLink>
										</NavItem>
									))}
									<LoginMenu onClick={onClickNavItem} />
								</Nav>
							</Collapse>
						</Navbar>
					</CardBody>
				</Card>
			</div>
		</IconContext.Provider>
	);
}
