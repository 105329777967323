import React, { createContext, useState } from 'react';

const MenuContext = createContext();

function MenuProvider(props) {
	const [selectedItem, setSelectedItem] = useState();

	return (
		<MenuContext.Provider value={{ selectedItem, setSelectedItem }}>
			{props.children}
		</MenuContext.Provider>
	);
}

export { MenuContext, MenuProvider };