const userStoreKey = 'SURGE.user';

export class UserManager {
	getUser() {
		const user = localStorage.getItem(userStoreKey);
		return JSON.parse(user);
	}

	storeUser(user) {
		const userToStore = JSON.stringify(user);
		localStorage.setItem(userStoreKey, userToStore);
	}

	removeUser() {
		localStorage.removeItem(userStoreKey);
	}
}