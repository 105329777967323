import React, { createContext, useContext, useMemo } from 'react';
import { useImmer } from 'use-immer';

const TOOLS_CONTEXT = createContext();

function ToolsProvider(props) {
	const [toolStates, setToolStates] = useImmer({});

	const contextValue = useMemo(() => [toolStates, setToolStates], [toolStates, setToolStates]);

	return (
		<TOOLS_CONTEXT.Provider value={contextValue}>
			{props.children}
		</TOOLS_CONTEXT.Provider>
	);
}

const useToolsContext = () => useContext(TOOLS_CONTEXT);

export { ToolsProvider, useToolsContext, TOOLS_CONTEXT };
