import React, { createContext, useState } from 'react';

const MapStyleContext = createContext();

function MapStyleProvider(props) {
	const [selectedMapStyle, setSelectedMapStyle] = useState('default');

	return (
		<MapStyleContext.Provider value={{ selectedMapStyle, setSelectedMapStyle }}>
			{props.children}
		</MapStyleContext.Provider>
	);
}

export { MapStyleContext, MapStyleProvider };
