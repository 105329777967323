import React from 'react';
import VisualizerProvider from '../../contexts/visualizer/VisualizerProvider';
import VisualizerContent from './VisualizerContent';

export default function Visualizer() {

	return (
		<VisualizerProvider>
			<VisualizerContent />
		</VisualizerProvider>
	);
}