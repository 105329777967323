import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FilterContext } from '../../../../../contexts/visualizer/FilterContext';

const options = [
	{ 'label': 'Show & use Area Filter', 'value': 'showUseAreaFilter' }
];

const AreaFilter = forwardRef((_, ref) => {

	const { useAreaFilter, setUseAreaFilter, prepareQuery } = useContext(FilterContext);

	useImperativeHandle(ref, () => ({
		onRevert() {
			setUseAreaFilter(false);
		},

		onClear() {
			setUseAreaFilter(false);
		}
	}));

	const onChange = async () => {
		const newValue = !useAreaFilter;
		setUseAreaFilter(newValue);

		if (!newValue) {
			await prepareQuery({ areaFilter: null });
		}
	};

	return (
		<div>
			<h4>Use Area Filter</h4>
			<FormGroup tag="fieldset">
				{options.map(option => (
					<FormGroup key={option.value || option.label} check inline>
						<Label check>
							<Input
								type="checkbox"
								checked={useAreaFilter}
								name="Use Area Filter"
								value={option.value}
								onChange={onChange}
								data-testid={`filter-${option.value || option.label}`}
							/>
							{option.label}
						</Label>
					</FormGroup>
				))}
			</FormGroup>
		</div>
	);
});

export default AreaFilter;