import React from 'react';
import { Route } from 'react-router';
import Account from './components/account/Account';
import Admin from './components/admin/Admin';
import Layout from './components/Layout';
import { ApplicationPaths } from './components/login/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/login/ApiAuthorizationRoutes';
import Visualizer from './components/visualizer/Visualizer';
import './custom.css';

export default function App() {
	return (
		<Layout>
			<Route exact path='/' component={Visualizer} />
			<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
			<Route exact path='/account' component={Account} />
			<Route exact path='/admin' component={Admin} />
		</Layout>
	);
}
