import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FilterContext } from '../../../../../contexts/visualizer/FilterContext';

const currentDate = moment().format('YYYY-MM-DD');
const oneYearBefore = moment().subtract(1, 'years').format('YYYY-MM-DD');

const DateFilter = forwardRef((_, ref) => {

	const { prepareQuery, setAllowFilter } = useContext(FilterContext);

	const [isError, setIsError] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);

	const startDateElement = useRef();
	const endDateElement = useRef();

	useImperativeHandle(ref, () => ({
		onRevert(startDate = '', endDate = '') {
			startDateElement.current.value = startDate;
			endDateElement.current.value = endDate;
		},

		onClear() {
			startDateElement.current.value = '';
			endDateElement.current.value = '';
		}
	}));

	const onChange = async (key, value) => {
		if (!value) {
			value = null;
		}

		const startDate = startDateElement.current.value;
		const endDate = endDateElement.current.value;

		let newErrorStatus = true;
		let newErrorMessage = null;

		if (
			(startDate && !(moment(startDate, 'YYYY-MM-DD', true).isValid()))
			|| (endDate && !(moment(endDate, 'YYYY-MM-DD', true).isValid()))) {
			newErrorStatus = true;
			newErrorMessage = '*Invalid date format';
		}
		else if (new Date(startDate) > new Date(endDate)) {
			newErrorStatus = true;
			newErrorMessage = '*Invalid date range';
		}
		else {
			newErrorStatus = false;
			newErrorMessage = '';
			await prepareQuery({ [key]: value });
		}

		setIsError(newErrorStatus);
		setErrorMessage(newErrorMessage);
		setAllowFilter(!newErrorStatus);
	};

	// useEffect here should run only the first time the component is rendered,
	// this is achieved with the empty array as the dependencies.
	// ES Lint gives a warning about missing dependencies, but the behavior is intended.
	// Disabling the warning below.
	useEffect(() => {
		prepareQuery({
			startDate: oneYearBefore,
			endDate: currentDate
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<div className="d-flex">
				<h4>Date</h4>
				{isError && <Label className="text-danger" data-testid="filter-date-notification">{errorMessage}</Label>}
			</div>
			<FormGroup tag="fieldset">
				<FormGroup check inline>
					<Label check>
						<Input
							type="date"
							placeholder="YYYY-MM-DD"
							name="Date"
							defaultValue={oneYearBefore}
							onChange={async (e) => await onChange('startDate', e.target.value)}
							onBlur={async (e) => await onChange('startDate', e.target.value)}
							data-testid="filter-Start Date"
							innerRef={startDateElement}
						/>
						Start Date
					</Label>
				</FormGroup>
				<FormGroup check inline>
					<Label check>
						<Input
							type="date"
							placeholder="YYYY-MM-DD"
							name="Date"
							defaultValue={currentDate}
							onChange={async (e) => await onChange('endDate', e.target.value)}
							onBlur={async (e) => await onChange('endDate', e.target.value)}
							data-testid="filter-End Date"
							innerRef={endDateElement}
						/>
						End Date
					</Label>
				</FormGroup>
			</FormGroup>
		</div>
	);
});

export default DateFilter;