import { Rectangle } from '@react-google-maps/api';
import React, { createRef, useContext } from 'react';
import { FilterContext } from '../contexts/visualizer/FilterContext';

const initialBounds = {
	north: 27.7196,
	south: 22.7196,
	east: 80.8577,
	west: 75.8577
};

const rectangleOptions = {
	fillColor: '#FF0000',
	fillOpacity: 0.35,
	strokeColor: '#FF0000',
	strokeOpacity: 0.8
};

const convertToBounds = (coordinates) => {
	const northEast = coordinates.getNorthEast();
	const southWest = coordinates.getSouthWest();
	return {
		north: northEast.lat(),
		south: southWest.lat(),
		east: northEast.lng(),
		west: southWest.lng()
	};
};

export default function AreaFilter() {

	const { prepareQuery } = useContext(FilterContext);

	const rectangleRef = createRef();

	const onBoundsChanged = async () => {
		let bounds = initialBounds;
		if (rectangleRef.current) {
			bounds = convertToBounds(rectangleRef.current.state.rectangle.getBounds());
		}

		await prepareQuery({ areaFilter: bounds });
	};

	return (
		<Rectangle
			options={rectangleOptions}
			bounds={initialBounds}
			onBoundsChanged={onBoundsChanged}
			editable
			draggable
			ref={rectangleRef}
		/>
	);
}