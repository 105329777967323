import React, { useState } from 'react';
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from 'reactstrap';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

export default function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [messageClass, setMessageClass] = useState('text-info');

	const setInfoMessage = (message, isError) => {
		if (isError) {
			setMessageClass('text-danger');
		} else {
			setMessageClass('text-info');
		}

		setMessage(message);
	};

	const navigateToReturnUrl = (returnUrl) => {

		// It's important that we do a replace here so that we remove the callback uri with the
		// fragment containing the tokens from the browser history.
		window.location.replace(returnUrl);
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const user = { 'username': username, 'password': password };

		const result = await authService.signIn(user);
		switch (result.status) {
			case AuthenticationResultStatus.Success:
				setInfoMessage('Login successful.', false);
				await navigateToReturnUrl('/');
				break;
			case AuthenticationResultStatus.Fail:
				setInfoMessage(result.message, true);
				break;
			default:
				throw new Error(`Invalid status result ${result.status}.`);
		}
	};

	return (
		<div>
			<Card>
				<CardBody>
					<CardTitle>
						<h1> Login </h1>
					</CardTitle>
					<Form onSubmit={onSubmit}>
						<FormGroup>
							<Label for="exampleUsername">Uername</Label>
							<Input
								type="text"
								name="username"
								id="exampleUsername"
								onChange={e => setUsername(e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="examplePassword">Password</Label>
							<Input
								type="password"
								name="password"
								id="examplePassword"
								onChange={e => setPassword(e.target.value)}
							/>
						</FormGroup>
						<Button>Submit</Button>
						{message &&
							<p
								className={`login-info-message ${messageClass}`}
								data-testid="login-info-message"
							>
								{message}
							</p>
						}
					</Form>
				</CardBody>
			</Card>
		</div>
	);
}