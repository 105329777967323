import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { MdAccountCircle, MdExitToApp, MdFileUpload, MdLockOutline, MdSecurity } from 'react-icons/md';
import { NavItem, NavLink } from 'reactstrap';
import { MenuContext } from '../../contexts/visualizer/MenuContext';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

const dataImportToolName = 'Data Import';

const LoginMenu = (props) => {
	const { selectedItem } = useContext(MenuContext);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userAccount, setUserAccount] = useState(null);

	const _subscription = useRef();

	const populateState = async () => {
		const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
		setIsAuthenticated(isAuthenticated);
		setUserAccount(user);
	};

	useEffect(() => {
		_subscription.current = authService.subscribe(() => populateState());
		populateState();

		return () => {
			authService.unsubscribe(_subscription.current);
		};
	}, []);

	const anonymousView = (loginPath) => {
		return (<Fragment>
			<NavItem>
				<NavLink
					href={loginPath}
					className="toggle-login"
					data-testid="navlink-login">

					<div className="d-flex align-items-center px-1">
						<MdLockOutline />
						<div className="spacer" />
						Login
					</div>
				</NavLink>
			</NavItem>
		</Fragment>);
	};

	const authenticatedView = () => {
		return (<Fragment>
			<NavItem>
				<NavLink
					onClick={() => props.onClick(dataImportToolName)}
					active={dataImportToolName === selectedItem}
					className="toggle-dataImport"
					data-testid="navlink-dataImport">

					<div className="d-flex align-item-center px-1">
						<MdFileUpload />
						<div className="spacer" />
						{dataImportToolName}
					</div>
				</NavLink>
			</NavItem>
			{userAccount?.roles?.includes('Admin') &&
				<NavItem>
					<NavLink
						href="/admin"
						className="toggle-admin"
						data-testid="navlink-admin">

						<div className="d-flex align-items-center px-1">
							<MdSecurity />
							<div className="spacer" />
							Admin
						</div>
					</NavLink>
				</NavItem>
			}
			<NavItem>
				<NavLink
					href="/account"
					className="toggle-account"
					data-testid="navlink-account">

					<div className="d-flex align-items-center px-1">
						<MdAccountCircle />
						<div className="spacer" />
						Account
					</div>
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink
					onClick={async () => await authService.signOut()}
					className="toggle-logout"
					data-testid="navlink-logout">

					<div className="d-flex align-items-center px-1">
						<MdExitToApp />
						<div className="spacer" />
						Logout
					</div>
				</NavLink>
			</NavItem>
		</Fragment>);
	};

	if (!isAuthenticated) {
		const loginPath = ApplicationPaths.Login;
		return anonymousView(loginPath);
	} else {
		return authenticatedView();
	}
};

export default LoginMenu;
