import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React, { useContext } from 'react';
import { Spinner } from 'reactstrap';
import loadActiveInfrastructureLayers from '../components/visualizer/tools/infrastructure/infrastructureMapFunction';
import getStyles from '../components/visualizer/tools/mapStyle/MapStyleResources';
import { FilterContext } from '../contexts/visualizer/FilterContext';
import { InfrastructureContext } from '../contexts/visualizer/InfrastructureContext';
import { MapStyleContext } from '../contexts/visualizer/MapStyleContext';
import AreaFilter from './AreaFilter';
import Clusterer from './clusterer/Clusterer';

const mapContainerStyle = {
	width: '100%',
	height: '100%'
};

// Center on Indore, India
const center = {
	lat: 22.7196,
	lng: 75.8577
};

const initialZoom = 4;

function findMapStyle(mapStyleResources, mapStyle) {

	// Parses styled map resources and compares to radio-button selection
	var finalMapStyle = [];
	var radioButtonStyle = mapStyle;
	for (const style of mapStyleResources) {
		if (style.value === radioButtonStyle) {
			finalMapStyle = style.style;
		}
	}

	return finalMapStyle;
}

export default function Map() {
	const mapOptions = {
		disableDefaultUI: true,
		zoomControl: true
	};

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
	});

	const { selectedLayers } = useContext(InfrastructureContext);
	const { useAreaFilter } = useContext(FilterContext);
	const { selectedMapStyle } = useContext(MapStyleContext);
	const style = findMapStyle(getStyles(), selectedMapStyle);
	const infrastructureMapStyle = loadActiveInfrastructureLayers(style, selectedLayers);

	const renderMap = () => {
		if (style !== []) {
			mapOptions.styles = infrastructureMapStyle;
		} else {
			mapOptions.styles = [];
		}

		return (
			<div className="position-absolute w-100 h-100">
				<GoogleMap
					options={mapOptions}
					center={center}
					mapContainerStyle={mapContainerStyle}
					zoom={initialZoom}
				>
					<Clusterer />
					{useAreaFilter && <AreaFilter />}
				</GoogleMap>
			</div>
		);
	};

	if (loadError) {
		return (
			<div className="d-flex position-absolute w-100 h-100">
				<h1 className="row align-self-center mx-auto">Error loading map</h1>
			</div>
		);
	}

	return isLoaded
		? renderMap()
		: <div className="d-flex position-absolute w-100 h-100">
			<Spinner className="row align-self-center mx-auto" />
		</div>;
}
