import React, { useContext } from 'react';
import Draggable from 'react-draggable';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';
import '../Tool.css';

export default function DataImport() {
	const { setSelectedItem } = useContext(MenuContext);

	return (
		<Draggable>
			<div className="tool">
				<Card>
					<CardBody>
						<div className="clearfix">
							<h3 className="float-left mb-0 mr-2">Data Import</h3>
							<Button onClick={() => setSelectedItem(null)} className="float-right" close />
						</div>
						<hr />
						<Input type="file" />
					</CardBody>
				</Card>
			</div>
		</Draggable>
	);
}