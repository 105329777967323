import React, { useContext, useState } from 'react';
import Tour from 'reactour';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';
import '../Tool.css';

const changeLayout = (collapseFilter, collapseNav) => {
	if (collapseFilter === document.querySelector('.filter-menu').classList.contains('show')) {
		document.querySelector('.filter-open-button').click();
	}

	if (collapseNav === document.querySelector('.navbar-collapse').classList.contains('show')) {
		document.querySelector('.navbar-toggler').click();
	}
};

const steps = [
	{
		content: 'Welcome to the SURGE Visualizer!',
		action: () => { changeLayout(true, true); }
	},
	{
		selector: '.filter-open-button',
		content: 'This button toggles the Filter.',
		action: () => { changeLayout(true, true); },
		stepInteraction: false
	},
	{
		selector: '.filter-content',
		content: 'This is the Filtering tool. It is for displaying unrest data on the map.',
		action: () => { changeLayout(false, true); },
		position: 'center',
		stepInteraction: false
	},
	{
		selector: '.menu',
		content: 'This is the menu where the all the additional tools are located.',
		action: () => { changeLayout(true, false); },
		stepInteraction: false
	},
	{
		selector: '.toggle-infrastructure-tool',
		content: 'This toggles the Infrastructure tool. It allows for choosing different infrastructure pieces to be displayed on the map.',
		action: () => { changeLayout(true, false); },
		stepInteraction: false
	},
	{
		selector: '.toggle-censusVariable-tool',
		content: 'This toggles the Census-Variable tool. It allows for choosing to display data based on different census variables.',
		action: () => { changeLayout(true, false); },
		stepInteraction: false
	},
	{
		selector: '.toggle-layers-tool',
		content: 'This toggles the Layers tool. It allows for choosing how to layer the unrest data on the map.',
		action: () => { changeLayout(true, false); },
		stepInteraction: false
	},
	{
		selector: '.toggle-mapStyles-tool',
		content: 'This toggles the Map Styles tool. It allows for choosing which map style to view unrest data on.',
		action: () => { changeLayout(true, false); },
		stepInteraction: false
	},
	{
		selector: '.toggle-project-info',
		content: 'This toggles the Project Information page. It contains the project owners as well as the motivation behind SURGE.',
		action: () => { changeLayout(true, false); },
		stepInteraction: false
	},
	{
		content: 'That concludes the tutorial. Feel free to play around with the different tools!',
		action: () => { changeLayout(true, true); }
	}
];

export default function Tutorial() {
	const { setSelectedItem } = useContext(MenuContext);
	const [isOpen, setIsOpen] = useState(true);

	const onClose = () => {
		localStorage.setItem('tutorialComplete', 'true');
		setIsOpen(false);
		setSelectedItem(null);
	};

	return (
		<div>
			<Tour
				steps={steps}
				isOpen={isOpen}
				onRequestClose={onClose}
				lastStepNextButton={'Done'}
			/>
		</div>
	);
}