import React, { useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';

import './ProjectInformation.css';

export default function ProjectInformation() {
	const { setSelectedItem } = useContext(MenuContext);

	const [open, setOpen] = useState(true);

	const toggle = () => {
		setOpen(!open);
	};

	const onClose = () => {
		if (localStorage.getItem('tutorialComplete') === null) {
			setSelectedItem('Tutorial');
		}
	};

	return (
		<div className="d-flex">
			<Modal
				centered
				isOpen={open}
				toggle={toggle}
				onClosed={onClose}
				className="mx-auto">

				<ModalHeader toggle={toggle}>Project Information</ModalHeader>
				<ModalBody>
					<div>
						<p><strong>PI</strong>: Dr. Deepti Joshi, The Citadel, The Military College of South Carolina, (djoshi@citadel.edu) <br />
							<strong>Co-PIs:</strong> Drs. Leen-Kiat Soh, Ashok Samal and Regina Werum, University of Nebraska-Lincoln  <br />
							(lksoh@cse.unl.edu, samal@cse.unl.edu, rwerum2@unl.edu) <br /> </p>
						<p><strong>Grant supported by:</strong> NGA Academic Research Program</p>
					</div>
					<div>
						<p>
							Growing world-wide instability caused by both governmental/state and non-state actors makes social unrest a great concern to researchers and policy makers.
							In recent years, big data approaches related to widespread use of internet-based and social media have revolutionized our ability to harness, understand, and even anticipate
							the occurrence and spread of social unrest.  Social scientific definitions of unrest typically revolve around sustained series of events that involve collective action a
							nd are aimed at producing some type of systemic change. Such events can be initiated by both state and non-state actors and can be aimed at a government or at other social
							institutions. The repertoire of such protest events, which have a tendency to occur in cycles of contention, includes non-violent demonstrations, strikes, riots, civil wars
							(including genocides), coups, revolts and revolutions/regime changes. Of particular interest to national security experts is the fact that these
							cycles of contention are speeding up and becoming ever more volatile, precisely because of technological changes in how information is transmitted and shared.
						</p>
						<p>
							In order to address the need to anticipate these unrest events, our goal is to create a system to detect and ultimately anticipate social unrest events in India/Pakistan/Bangladesh
							using an integrated approach which combines Computer Science data-driven techniques and Social Science model-driven methodologies. As a result, this research will further
							scientific approaches to improving situational awareness by identifying short-term triggers that spark social unrest, and factors that fuel long-term social unrest events.
						</p>
						<p>
							This research has five vital components:
						</p>
						<ol>
							<li>Develop social unrest vocabulary for our region of interest to perform content analysis of classic media.</li>
							<li>Apply a dynamic version of this vocabulary to social media data.</li>
							<li>Develop spatio-temporal algorithms to mine the data collected from both traditional and social media sources.</li>
							<li>Develop a social science theory based explanatory model to identify long term triggers of social unrest.</li>
							<li>Develop a multiagent simulation testbed for entity--entity interactions across time and space to analyze and predict short-term social unrest triggers modeling and simulation.</li>
						</ol>
						<p>
							All of these components will then be integrated into a social unrest anticipatory system with a map based front end
							called SURGE (Social Unrest Reconnaissance GazEtteer).
						</p>
						<p>
							Currently the primary source for event data is the Global Database of Events, Language, and Tone (GDELT) - http://gdeltproject.org/.
							GDELT offers a platform that monitors the news media from all over the world in print, broadcast, and web formats, in over 100 languages,
							every moment of every day. It stretches back to January 1, 1979 through present day, with daily updates. We are working on adding Times of India's newspaper archives and
							Twitter feeds to our dataset.
						</p>
						<p>
							The expected benefits from SURGE are to find answers for the following questions:
						</p>
						<ul>
							<li>What are the different types of social unrest events?</li>
							<li>Where and when do they occur?</li>
							<li>How do unrest events evolve/diffuse over space and time? </li>
							<li>What was the situation before and after a key event? </li>
							<li>What are long-term trends vs. short-term trends?</li>
							<li>What are the main hotspots?</li>
							<li>What is happening in a specific region of interest (ROI)?</li>
						</ul>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
