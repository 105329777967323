import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';

const FilterContext = createContext();

function FilterProvider(props) {
	const [useAreaFilter, setUseAreaFilter] = useState(false);

	const [query, setQuery] = useState({});

	const [eventCount, setEventCount] = useState(0);
	const [isFetchingCount, setIsFetchingCount] = useState(false);
	const [allowFilter, setAllowFilter] = useState(true);

	const isMounted = useRef(true);

	const countEventData = useCallback(async (selectedFilter) => {

		// Prevent sending request while another is in progress
		if (isFetchingCount) {
			return;
		}

		setIsFetchingCount(true);

		const options = {
			method: 'POST',
			body: JSON.stringify(selectedFilter),
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const response = await fetch('api/v1/event/count', options);
		const data = await response.json();

		// Safely set state if the component is mounted
		if (isMounted.current) {
			setIsFetchingCount(false);
			setEventCount(data);
		}
	}, [isFetchingCount]);

	const prepareQuery = useCallback(async (data) => {
		const newQuery = { ...query, ...data };

		await countEventData(newQuery);
		setQuery(newQuery);
	}, [countEventData, query]);

	// useEffect here should run only the first time the component is rendered,
	// this is achieved with the empty array as the dependencies.
	// ES Lint gives a warning about missing dependencies, but the behavior is intended.
	// Disabling the warning below.
	useEffect(() => {
		(async () => await countEventData(query))();

		return () => {
			isMounted.current = false;
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<FilterContext.Provider value={{
			useAreaFilter,
			setUseAreaFilter,
			eventCount,
			setEventCount,
			query,
			setQuery,
			prepareQuery,
			allowFilter,
			setAllowFilter
		}}>
			{props.children}
		</FilterContext.Provider>
	);
}

export { FilterContext, FilterProvider };
