import { UserManager } from './UserManager';

export class AuthorizeService {
	_callbacks = [];
	_nextSubscriptionId = 0;
	_user = null;
	_isAuthenticated = false;

	async isAuthenticated() {
		const user = await this.getUser();
		return !!user;
	}

	async getUser() {
		if (this._user) {
			return this._user;
		}

		this.ensureUserManager();
		const user = this.userManager.getUser();
		return user;
	}

	async signIn(user) {
		try {
			const options = {
				method: 'POST',
				body: JSON.stringify(user),
				headers: {
					'Content-Type': 'application/json'
				}
			};

			const response = await fetch('api/v1/userAccount/login', options);
			if (!response.ok) {
				return this.error('The username or password is invalid.');
			}

			const userData = await response.json();

			this.ensureUserManager();
			this.userManager.storeUser(userData);
			this.updateState(userData);
			return this.success();
		} catch (error) {
			console.log('There was an error signing in: ', error);
			return this.error('There was an error signing in.');
		}
	}

	async signOut(state) {
		try {
			const options = {
				method: 'POST'
			};

			const response = await fetch('api/v1/userAccount/logout', options);
			if (!response.ok) {
				return this.error('There was an error trying to log out.');
			}

			this.ensureUserManager();
			this.userManager.removeUser();
			this.updateState(null);
			return this.success(state);
		} catch (error) {
			console.log(`There was an error trying to log out '${error}'.`);
			return this.error(error);
		}
	}

	updateState(user) {
		this._user = user;
		this._isAuthenticated = !!this._user;
		this.notifySubscribers();
	}

	subscribe(callback) {
		this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
		return this._nextSubscriptionId - 1;
	}

	unsubscribe(subscriptionId) {
		const subscriptionIndex = this._callbacks
			.map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
			.filter(element => element.found === true);
		if (subscriptionIndex.length !== 1) {
			throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
		}

		this._callbacks.splice(subscriptionIndex[0].index, 1);
	}

	notifySubscribers() {
		for (let i = 0; i < this._callbacks.length; i++) {
			const callback = this._callbacks[i].callback;
			callback();
		}
	}

	error(message) {
		return { status: AuthenticationResultStatus.Fail, message };
	}

	success(state) {
		return { status: AuthenticationResultStatus.Success, state };
	}

	ensureUserManager() {
		if (this.userManager !== undefined) {
			return;
		}

		this.userManager = new UserManager();
	}

	static get instance() { return authService; }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
	Success: 'success',
	Fail: 'fail'
};
