import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FilterContext } from '../../../../../contexts/visualizer/FilterContext';

const { SHOW_PARENT } = TreeSelect;

const treeData = [
	{ 'title': 'Coerce', 'value': 'coerce' },
	{ 'title': 'Fight', 'value': 'fight' },
	{ 'title': 'Assault', 'value': 'assault' },
	{ 'title': 'Appeal', 'value': 'appeal' },
	{ 'title': 'Demand', 'value': 'demand' },
	{ 'title': 'Threaten', 'value': 'threaten' },
	{ 'title': 'Protest', 'value': 'protest' },
	{ 'title': 'Engage in UMV', 'value': 'umv' },
	{ 'title': 'Terrorism', 'value': 'terrorism' }
];

const UnrestFilter = forwardRef((_, ref) => {

	const { prepareQuery } = useContext(FilterContext);

	const [isError, setIsError] = useState(true);
	const [value, setValue] = useState([]);

	useImperativeHandle(ref, () => ({
		onRevert(selectedNodes) {
			const length = selectedNodes?.length;
			setIsError(!length || length === 0);
			setValue(selectedNodes);
		},

		onClear() {
			setIsError(true);
			setValue([]);
		}
	}));

	const onChange = async (selectedNodes) => {
		setIsError(selectedNodes.length === 0);
		setValue(selectedNodes);
		await prepareQuery({ unrestEvent: selectedNodes });
	};

	const treeProps = {
		treeData,
		value: value,
		onChange: onChange,
		treeCheckable: true,
		treeNodeFilterProp: 'title',
		showCheckedStrategy: SHOW_PARENT,
		placeholder: 'Select unrest events',
		style: {
			width: '100%'
		}
	};

	return (
		<div>
			<div className="d-flex">
				<h4>Unrest Event</h4>
				{isError && <Label className="text-danger" data-testid="filter-unrestEvent-notification">*Required</Label>}
			</div>
			<FormGroup tag="fieldset">
				<TreeSelect {...treeProps} />
			</FormGroup>
		</div>
	);
});

export default UnrestFilter;