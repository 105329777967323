import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../../contexts/visualizer/FilterContext';

const initialNotificationMessage = 'Apply the filter to view ';
const onChangeNotificationMessage = `You have unsaved changes. ${initialNotificationMessage}`;

export default function FilterNotificationMessage() {

	const { eventCount } = useContext(FilterContext);

	const [notificationMessage, setNotificationMessage] = useState(initialNotificationMessage);

	const createEventCountNotificationMessage = useCallback(() => {
		let eventsPluralized = (eventCount === 1) ? ' event' : ' events';

		if (!notificationMessage || notificationMessage.startsWith(onChangeNotificationMessage)) {
			setNotificationMessage(`${onChangeNotificationMessage} ${eventCount.toLocaleString()} ${eventsPluralized}`);
		} else {
			setNotificationMessage(`${initialNotificationMessage} ${eventCount.toLocaleString()} ${eventsPluralized}`);
		}

	}, [eventCount, notificationMessage]);

	// useEffect here should only run when the count is changed.
	// Including createEventCountNotificationMessage in the dependency array
	// causes it to be triggered too often.
	// Disabling the warning below.
	useEffect(() => {
		createEventCountNotificationMessage();
	}, [eventCount]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			{notificationMessage &&
				<p
					className="filter-notification-message text-danger"
					data-testid="filter-notification-message"
				>
					{notificationMessage}
				</p>
			}
		</Fragment>
	);
}