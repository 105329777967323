import React, { createContext, useState } from 'react';

const InfrastructureContext = createContext();

function InfrastructureProvider(props) {
	const [selectedLayers, setSelectedLayers] = useState(['']);

	return (
		<InfrastructureContext.Provider value={{ selectedLayers, setSelectedLayers }}>
			{props.children}
		</InfrastructureContext.Provider>
	);
}

export { InfrastructureContext, InfrastructureProvider };
