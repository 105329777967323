import React, { useContext } from 'react';
import Draggable from 'react-draggable';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { MapStyleContext } from '../../../../contexts/visualizer/MapStyleContext';
import { MenuContext } from '../../../../contexts/visualizer/MenuContext';
import '../Tool.css';

const toolName = 'mapStyle';

export default function MapStyle() {
	const { setSelectedItem } = useContext(MenuContext);
	const { selectedMapStyle, setSelectedMapStyle } = useContext(MapStyleContext);

	const mapStyles = [
		{ 'label': 'Map (default)', 'value': 'default' },
		{ 'label': 'Grayscale (Clean)', 'value': 'cleanGray' },
		{ 'label': 'Subtle Grayscale', 'value': 'subtleGray' },
		{ 'label': 'Ultra light', 'value': 'ultralight' },
		{ 'label': 'Clean (no labels)', 'value': 'cleanNoLabel' },
		{ 'label': 'Clean (roads)', 'value': 'cleanRoads' }
	];

	return (
		<Draggable>
			<div className="tool">
				<Card>
					<CardBody>
						<div className="clearfix">
							<h3 className="float-left mb-0 mr-2">Map Style</h3>
							<Button onClick={() => setSelectedItem(null)} className="float-right" close />
						</div>
						<hr />
						<FormGroup tag="fieldset">
							{mapStyles.map(mapStyle => (
								<FormGroup key={mapStyle.value} check>
									<Label check>
										<Input
											type='radio'
											name={toolName}
											checked={selectedMapStyle === mapStyle.value}
											value={mapStyle.value}
											onChange={() => setSelectedMapStyle(mapStyle.value)}
											data-testid={`${toolName}-${mapStyle.value}`}
										/>
										{mapStyle.label}
									</Label>
								</FormGroup>
							))}
						</FormGroup>
					</CardBody>
				</Card>
			</div>
		</Draggable>
	);
}

